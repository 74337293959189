import React, { Component } from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';

const Holder = styled.p`
  text-align: center;
  font-style: italic;
  font-weight: 500;
  ${props => props.theme.typeStyles( 3 )};
  @media ( ${props => props.theme.breakpoints.sm} ) {
    ${props => props.theme.typeStyles( 4 )};
  }
`;

class Strapline extends Component {
  render() {
    return (
      <Container>
        <Holder>Durable. Stylish. Affordable.</Holder>
      </Container>
    )
  }
}

export default Strapline;
