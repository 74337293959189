import React, { Component } from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';

const Holder = styled.footer`
  display: grid;
  padding: 0 2rem;
  grid-template-columns: 1fr;
  grid-column-gap: 6rem;
  text-align: center;
  @media ( ${props => props.theme.breakpoints.sm} ) {
    grid-template-columns: 1fr 1fr;
    text-align: left;
    > :last-child {
      text-align: right;
    }
  }
`;

class Footer extends Component {
  render() {
    return (
      <Container>
        <Holder>
          <p>
            <a href="tel:+4407796360797">+44 (0) 77 9636 0797</a><br/>
            <a href="mailto:willmcl@gmail.com">willmcl@gmail.com</a><br/>
          </p>
          <p>
            <a href="https://www.instagram.com/willswebsites/" target="_blank" rel="noopener noreferrer">@willswebsites</a>
          </p>
        </Holder>
      </Container>
    )
  }
}

export default Footer;
