import React, { Component } from 'react';
import styled from 'styled-components';
import Logo from '../atoms/Logo';
import Container from '../atoms/Container';

const Holder = styled.header`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  grid-auto-flow: dense;
  font-weight: 500;
  @media ( ${props => props.theme.breakpoints.sm} ) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 2rem;
  }
  h1, p { margin: 0; }
  .text { text-align: center; }
  .leftContent {
    display: flex;
    justify-content: center;
    @media ( ${props => props.theme.breakpoints.sm} ) {
      justify-content: flex-start;
    }
  }
  .rightContent {
    display: flex;
    font-style: italic;
    justify-content: center;
    @media ( ${props => props.theme.breakpoints.sm} ) {
      justify-content: flex-end;
    }
  }
  .image {
    display: flex;
    justify-content: center;
    @media ( ${props => props.theme.breakpoints.sm} ) {
      grid-column: 2/3;
    }
  }
`;

class Header extends Component {
  render() {
    return (
      <Container>
        <Holder>
          <div className="image">
            <Logo/>
          </div>
          <div className="leftContent">
            <div className="text">
              <h1>Will's Websites</h1>
              <p><a href="tel:+4407796360797">+44 (0) 77 9636 0797</a><br/></p>
            </div>
          </div>
          <div className="rightContent">
            <div className="text">
              <p>World-class tech<br/>for the people</p>
            </div>
          </div>
        </Holder>
      </Container>
    )
  }
}

export default Header
